<template>
  <span v-if="ad === iad">
    {{ad}}
  </span>
  <span v-else>
    {{ad}}/{{iad}}
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          small
          style="vertical-align: baseline; margin-left: -10px"
          color="grey ligthen-2"
        >far fa-info-circle</v-icon>
      </template>
      <span>{{ad}} for beach events & {{iad}} for indoor events</span>
    </v-tooltip>
  </span>
</template>

<script>

export default {
  props: ['player', 'showInfo', 'usav'],
  computed: {
    ad () {
      return this.usav ? this.iad : this.player.ageDivision.replace('Adult', '')
    },
    iad () {
      return this.player.indoorAgeDivision.replace('Adult', '')
    }
  }
}
</script>
